<template>
  <div class="" v-if="item">
    <div class="news-view" v-if="item">
      <div class="news-cate">
        <div class="label">{{ item.category }}</div>
      </div>
      <h2 class="news-title">{{ item.title }}</h2>
      <div><img :src="item.image" style="width: 100%" /></div>
      <div class="layout mt-8 flex-column-reverse flex-md-row">
        <div class="news-content" v-html="item.content"></div>
        <div class="px-md-3">
          <div class="news-date">{{ $moment(item.date).format("DD MMM YYYY") }}</div>
          <div class="news-location">{{ item.location }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeRouteUpdate(to, form, next) {
    this.load();
    next();
  },
  data() {
    return {
      item: null,
      categories: []
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.$axios.post("/news/getItem", { key: this.$route.params.id }).then((data) => {
        if (data) {
          this.item = data;
        } else {
          this.$router.replace("/error/404");
        }
      });
    }
  }
};
</script>

<style lang="scss">
.news-view {
  max-width: 1300px;
  margin: 0 auto;
  > .news-cate {
    display: flex;
    > .label {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
      line-height: 1;
      color: var(--primary-color);
    }
  }
  > .news-title {
    margin: 0.5em 0;
    font-size: 22px;
    line-height: 1.5;
  }
  .news-date {
    min-width: 180px;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 2;
    color: rgba(0, 0, 0, 0.6);
  }
  .news-location {
    font-weight: bold;
    color: var(--primary-color);
  }
  iframe {
    width: 100%;
    height: 56vw;
  }
}
@media (min-width: 960px) {
  .news-view {
    padding: 24px;

    iframe {
      width: 620px;
      height: 460px;
    }
  }
}
@media (min-width: 1264px) {
  .news-view {
    > .news-title {
      font-size: 32px;
      line-height: 1.5;
    }
    .news-content {
      padding: 0 36px;
    }
    .news-date {
      min-width: 340px;
    }
    iframe {
      width: 820px;
      height: 460px;
    }
  }
}
</style>
