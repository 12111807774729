<template>
  <div class="md-home">
    <div class="header">
      <img src="/img/top-img-02.jpg" style="width: 100%" />
    </div>
    <h1 style="margin: 32px 0; text-transform: uppercase">{{ $moment().format("DD MMM YYYY") }}</h1>
    <div class="layout">
      <div class="news-cards">
        <div class="news-card" v-for="news in newsList" :key="news.id">
          <div class="inner">
            <div class="news-cate">
              <div class="label">{{ news.category }}</div>
            </div>
            <div class="news-second">
              <router-link :to="`/news/${news.key}`"><img :src="news.image" /></router-link>
              <div class="cap">
                <span class="mr-2">{{ $moment(news.date).format("DD MMM YYYY") }}</span>
                <span class="pl-2 secondary--text" style="border-left: solid 1px currentColor">{{ news.location }}</span>
              </div>
            </div>
            <div class="news-content">
              <router-link :to="`/news/${news.key}`">
                <h4>{{ news.title }}</h4>
              </router-link>
              <div>{{ news.abstract }}</div>
            </div>
          </div>
          <div class="action">
            <v-btn color="primary" fab :to="`/news/${news.key}`"><v-icon large>mdi-arrow-right</v-icon></v-btn>
          </div>
        </div>
      </div>
      <div class="md-nf">
        <h4>Filter by Type</h4>
        <div class="nfm">
          <v-checkbox v-for="(l, i) in types" :key="i" v-model="filters.types" color="primary" :label="l" :value="l" hide-details />
        </div>
        <h4>Filter by Location</h4>
        <div class="nfm">
          <v-checkbox v-for="(l, i) in locations" :key="i" v-model="filters.locations" color="primary" :label="l" :value="l" hide-details />
        </div>
        <h4>Filter by Time</h4>
        <v-menu offset-y max-height="320px">
          <template v-slot:activator="{ on }">
            <v-text-field
              placeholder="Select month..."
              readonly
              class="primary--text"
              solo
              append-icon="mdi-menu-down"
              v-on="on"
              v-model="filters.date"
              hide-details
              clearable
            />
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in dates" :key="index" @click="filters.date = item">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <div class="justify-space-around layout mt-8 pa-3 white elevation-1">
          <v-btn icon color="primary" href="https://www.instagram.com/musicdaily.co.uk/"><v-icon>mdi-instagram</v-icon></v-btn>
          <v-btn icon color="primary" href="https://x.com/MusicDaily_uk"><v-icon>mdi-twitter</v-icon></v-btn>
          <v-btn icon color="primary" href="https://www.threads.net/@musicdaily.co.uk?hl=en">
            <img src="/img/threads-1-01.svg" style="width: 24px" />
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mutations, states } from "@/constants";
export default {
  data() {
    return {
      newsList: [],
      locations: ["UK", "World", "Europe"],
      types: ["News", "Review", "Competition"],
      filters: {
        types: [],
        locations: [],
        date: ""
      },
      page: 1,
      pages: 1,
      pageSize: 12,
      dates: []
    };
  },
  computed: {
    loading() {
      return this.$store.state[states.loading];
    },
    cFilter() {
      var filter = [];
      if (this.filters.types.length) {
        filter.push({ field: "category", value: this.filters.types.join("%"), operator: "In" });
      }
      if (this.filters.locations.length) {
        filter.push({ field: "location", value: this.filters.locations.join("%"), operator: "In" });
      }
      if (this.filters.date) {
        var date = this.$moment(this.filters.date);
        filter.push({ field: "date", value: date.clone().startOf("M"), operator: "Gte" });
        filter.push({ field: "date", value: date.clone().endOf("M"), operator: "Lte" });
      }
      return filter;
    }
  },
  methods: {
    scrollLoad() {
      var el = document.querySelector("body");
      var end = el.clientHeight - window.scrollY - window.innerHeight;
      if (end < 300 && !this.loading) {
        if (this.page < this.pages) {
          this.page++;
          this.loadNews().then((data) => {
            this.newsList = this.newsList.concat(data);
          });
        }
      }
    },
    loadNews() {
      return this.$axios.post("/news/list", { filter: this.cFilter, sort: [{ field: "date", asc: false }], page: this.page, pageSize: this.pageSize });
    },
    reset() {
      this.$store.commit(mutations.toggleLoading, true);
      this.page = 1;
      this.$axios
        .post("/news/count", { filter: this.cFilter })
        .then((data) => {
          this.pages = Math.min(Math.ceil(data.total / this.pageSize), 10);
          return this.loadNews();
        })
        .then((data) => {
          this.newsList = data;
          this.$store.commit(mutations.toggleLoading, false);
        });
    }
  },
  created() {
    for (var i = 0; i < 12; i++) {
      var date = this.$moment().subtract(i, "month");
      this.dates.push(date.format("MMM YYYY"));
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollLoad);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollLoad);
  },
  watch: {
    filters: {
      handler: "reset",
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss">
.news-card {
  position: relative;
  margin-top: 24px;
  display: block;
  &::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 24px;
    bottom: 24px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .inner {
    position: relative;
    z-index: 1;
    .news-cate {
      flex: 0;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      .label {
        font-size: 13px;
        font-weight: bold;
        line-height: 1;
        background: var(--secondary-color);
        color: #fff;
        padding: 12px 0;
        min-width: 120px;
        text-align: center;
      }
    }
    .news-second {
      text-transform: uppercase;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.6);
      padding: 0 24px;
      img {
        width: 100%;
        cursor: pointer;
      }
    }
    .news-content {
      flex: 1 1 auto;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: rgba(0, 0, 0, 0.6);
      > * {
        line-height: 1.5em;
        max-height: 6em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
      > a {
        font-size: 28px;
        line-height: 1.5em;
        max-height: 4.5em;
        -webkit-line-clamp: 3;
        margin-bottom: 0.75em;
        > h4 {
          flex: 1 0 0%;
          line-height: 1.5em;
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
  .action {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: right;
    padding-right: 24px;
  }
}
.md-nf {
  display: none;
  padding: 24px 24px 0;
  > h4 {
    margin: 1em 0 0.5em;
  }
  > .nfm {
    padding: 12px 24px 24px;
    background: #fff;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .v-input input,
  .v-input .v-label,
  .v-icon {
    color: var(--primary-color);
  }
}
@media (min-width: 960px) {
  .news-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 1px;
    flex: 1 0 0%;
    .news-card {
      flex: 1 0 50%;
      max-width: 50%;
      &:nth-child(2n + 1) {
        margin-right: 24px;
        max-width: calc(50% - 24px);
      }
    }
  }
  .md-nf {
    display: block;
    min-width: 280px;
    max-width: 280px;
  }
}
@media (min-width: 1264px) {
  .news-cards {
    display: flex;
    flex-wrap: wrap;
    .news-card {
      flex: 1 0 100%;
      min-width: 100%;
      max-width: unset;
      margin-right: 24px;
      &:nth-child(2n + 1) {
        max-width: unset;
      }
    }
  }
  .news-card {
    position: relative;
    margin-left: 0;
    &::after {
      left: 24px;
    }
    .inner {
      display: flex;
      flex-wrap: nowrap;
      .news-second {
        flex: 0 0 40%;
        min-width: 40%;
        padding: 0;
        img {
          width: 100%;
        }
        > .cap {
          text-align: right;
        }
      }
      .news-content {
        margin-top: 24px;
        padding: 32px;
        padding-bottom: 0;
      }
    }
  }
}
</style>
